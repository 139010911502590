<template>
  <!-- 修改合包 -->
  <div class="container">
    <el-button type="warning" size="mini" @click="saveData">保存修改</el-button>
    <!-- 查询条件 -->
    <div class="newForm search">
      <el-form ref="searchForm" :model="searchForm" label-width="100px">
        <div class="inlineDiv">
          <div class="queryItem1" style="width: 290px">
            <el-form-item label="申请打包单号:" prop="memberId">
              <el-input
                v-model="searchForm.billPackNumber"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="会员号:" prop="memberId">
              <el-select
                class="queryInput"
                @change="huiayu"
                v-model="searchForm.memberId"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembers"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in huiyuanList"
                  :label="item.memberId"
                  :value="item.memberId"
                  :key="index"
                >
                  {{ item.memberId }}
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <!-- <div class="queryItem1">
            <el-form-item label="会员名称:" prop="nickname">
              <el-select
                class="queryInput"
                @change="huiayuName"
                v-model="searchForm.nickname"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembersName"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in huiyuanList"
                  :label="item.nickname"
                  :value="item.nickname"
                  :key="index"
                >
                  {{ item.nickname }}
                </el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <el-button type="warning" size="mini" @click="cx">查 询</el-button>
        </div>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="transfer">
      <el-row>
        <el-col :span="11">
          <div class="leftTable">
            <!-- 左边列表 -->
            <div class="titleTip">申请打包单列表</div>
            <el-table
              :data="leftTable"
              ref="leftTableRef"
              @row-click="rowclick"
              size="mini"
              stripe
              highlight-current-row
              border
              @selection-change="notJoinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>
              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="billPackNumber"
                label="申请打包单号"
                align="center"
                :show-overflow-tooltip="true"
                min-width="168"
              >
              </el-table-column>
              <el-table-column
                prop="memberName"
                align="center"
                min-width="100"
                label="会员名称"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>

            <paging
              :pageNum="pageStart"
              :total="pageCount"
              :sizeList="sizeList"
              :size="pageTotal"
              @handleSizeChange="pageSizeChangeLeft"
              @handleCurrentChange="pageCurrChangeLeft"
            >
            </paging>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="btnBox">
            <el-button
              type="primary"
              size="small"
              class="crowBtns"
              style="margin-bottom: 40px"
              @click="move"
              :disabled="status_ === 0"
              icon="el-icon-arrow-left"
            >
              向左移
            </el-button>
            <el-button
              class="crowBtns"
              type="success"
              size="small"
              :disabled="status_ === 1"
              @click="move"
            >
              向右移 <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </div>
        </el-col>
        <!-- 右边列表 -->
        <el-col :span="11">
          <div class="rightTable">
            <div class="titleTip">合包单已包含</div>
            <el-table
              :data="rightTable"
              ref="RightTableRef"
              @row-click="rowclick2"
              stripe
              border
              size="mini"
              @selection-change="joinSelectionChange"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column type="selection" align="center" width="55">
              </el-table-column>

              <el-table-column
                prop="storageName"
                align="center"
                label="仓库"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="billPackNumber"
                label="申请打包单号"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="memberName"
                align="center"
                min-width="100"
                label="会员名称"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                align="center"
                min-width="100"
                label="会员号"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";

export default {
  inject: ["reload"],
  components: { paging },
  data() {
    return {
      searchForm: {
        billPackNumber: "",
        memberId: "",
      }, //  表格搜索数据
      leftTable: [], //  左边表格数据
      rightTable: [], //  右边表格数据
      status_: 2,
      selectedData: [], //  左边表格选中的数据
      selectedData_right: [], // 右边 表格选中的数据

      huiyuanList: [], //会员列表
      pageStart: 1,
      pageTotal: 20,
      pageCount: 0,
      sizeList: [20, 50, 100, 200],

      billClosePackageId: "",
    };
  },
  created() {
    this.getVipList();
  },
  activated() {
    if (this.$route.query.id || this.billClosePackageId) {
      this.billClosePackageId = this.$route.query.id || this.billClosePackageId;
      if (this.billClosePackageId) {
        this.getleftList(); //  合包详情
      }
    }
  },
  methods: {
    rowclick(row, event, column) {
      this.$refs.leftTableRef.toggleRowSelection(row);
    },
    rowclick2(row, event, column) {
      this.$refs.RightTableRef.toggleRowSelection(row);
    },
    saveData() {
      if (this.rightTable.length < 2) {
        this.$message.warning("右边的合包单不能小于2");
        return false;
      }
      let ids = [];
      this.rightTable.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackIds: ids,
        billClosePackageId: this.billClosePackageId,
        comment: "",
      };
      param.sign = tools.getSign(param);
      Api.updateBillClosePackageV2(param).then((res) => {
        this.$message.success(res.data.message || "修改成功");
        tools.closePage();
        this.$router.push("/pages/PackageList");
        this.reload();
      });
    },
    //远程搜索(包裹预报)
    getMembers(e) {
      this.getVipList(e);
    },
    getMembersName(e) {
      console.log(e);
      this.getVipList("", e);
    },

    // 获取会员列表
    getVipList(e, n) {
      //  获取会员列表
      Api.getMemberDownList({ memberId: e || "", nickname: n || "" }).then(
        (res) => {
          if (res.data.status === "success") {
            this.huiyuanList = res.data.result.data || [];
          }
        }
      );
    },

    // 选择会员
    huiayu(e) {
      if (!e) {
        this.searchForm.nickname = "";
        this.searchForm.memberId = "";
        this.userId = "";
        this.getVipList();
        return false;
      }
      this.huiyuanList.forEach((item) => {
        if (e == item.memberId) {
          this.searchForm.nickname = item.nickname;
          this.userId = item.userId;
        }
      });
    },
    // 选择会员
    huiayuName(e) {
      if (!e) {
        this.searchForm.nickname = "";
        this.searchForm.memberId = "";
        this.userId = "";
        this.getVipList();
        return false;
      }
      this.huiyuanList.forEach((item) => {
        if (e == item.nickname) {
          this.searchForm.memberId = item.memberId;
          this.userId = item.userId;
        }
      });
    },

    getleftList() {
      //  左边列表
      let param = {
        billPackNumber: this.searchForm.billPackNumber,
        memberId: this.searchForm.memberId,
        pageStart: this.pageStart || 1,
        pageTotal: this.pageTotal,
      };
      Api.getPackPackagetSelectListV2(param).then((res) => {
        if (res.data.status === "success") {
          this.leftTable = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;

          // 右边列表
          this.myGetDownframeDetail(); //  合包详情
        } else {
          this.$message.error(`${res.data.message}--待下架列表`);
        }
      });
    },
    // 分页
    pageSizeChangeLeft(val) {
      this.pageTotal = val;
      this.getleftList();
    },
    pageCurrChangeLeft(val) {
      this.pageStart = val;
      this.getleftList();
    },

    myGetDownframeDetail() {
      //  合包详情
      let params = {
        billClosePackageId: this.billClosePackageId,
        billPackNumber: this.searchForm.billPackNumber,
        memberId: this.searchForm.memberId,
      };
      Api.getBillCloseBillPackListV2(params).then((res) => {
        if (res.data.status === "success") {
          this.rightTable = res.data.result || [];

          for (var i = 0; i < this.rightTable.length; i++) {
            for (var j = 0; j < this.leftTable.length; j++) {
              if (
                this.leftTable[j].billPackId == this.rightTable[i].billPackId
              ) {
                this.leftTable.splice(j, 1);
                j--;
              }
            }
          }
        } else {
          this.$message.error(`${res.data.message}--待下架列表`);
        }
      });
    },

    cx() {
      //  未入团搜索查询
      this.pageStart = 1;
      this.getleftList();
    },
    notJoinSelectionChange(e) {
      this.selectedData = e;
      this.status_ = 0;
    },
    joinSelectionChange(e) {
      this.selectedData_right = e;
      this.status_ = 1;
    },
    move() {
      //  左右移动

      if (this.status_ === 0) {
        if (this.selectedData.length < 1) {
          this.$message.warning("左边还没选到数据");
          return false;
        }
        this.$confirm(
          `将选中的${this.selectedData.length}条数据移到右边合包, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          // this.selectedData.forEach((item) => {
          //   this.rightTable.push(item);
          // });
          let leftList = this.selectedData || [];
          for (var a = 0; a < leftList.length; a++) {
            this.rightTable.push(leftList[a]);

            for (var b = 0; b < this.leftTable.length; b++) {
              if (this.leftTable[b].billPackId == leftList[a].billPackId) {
                this.leftTable.splice(b, 1);
                b--;
              }
            }
          }
        });
      } else if (this.status_ === 1) {
        if (this.selectedData_right.length < 1) {
          this.$message.warning("右边还没选到数据");
          return false;
        }
        this.$confirm(
          `将选中的${this.selectedData_right.length}条数据移回左边, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          let rightList = this.selectedData_right || [];
          for (var a = 0; a < rightList.length; a++) {
            this.leftTable.push(rightList[a]);

            for (var b = 0; b < this.rightTable.length; b++) {
              if (this.rightTable[b].billPackId == rightList[a].billPackId) {
                this.rightTable.splice(b, 1);
                b--;
              }
            }
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team {
  margin-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px silver solid;
  .queryItem1 {
    /deep/ .el-radio {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.search {
  border-bottom: 1px silver solid;
  // padding: 20px 0;
  padding-bottom: 10px;
  .queryItem1 {
    width: 20%;
  }
}

.container {
  margin: 0 20px;
}

.btnBox {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(200%);
}

.leftTable {
  padding-right: 16px;
  // height: 590px;
  height: calc(100vh - 180px);
  border-right: 1px silver solid;
}

.rightTable {
  padding-left: 16px;
  border-left: 1px silver solid;
  // height: 590px;
  height: calc(100vh - 180px);
}

.transfer {
  border-bottom: 1px silver solid;
  height: calc(100vh - 180px);
}

.footbut {
  text-align: right;
  margin-top: 10px;
}

.titleTip {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #888888;
}

.inlineDiv {
  display: flex;
  align-items: center;
}

.crowBtns {
  width: 80px;
  text-align: center;
  margin: 0;
  padding: 10px 4px;
}
</style>
